<template>
	<button aria-label="Open contextual menu" v-on:click="createContextMenu" ref="contextMenuTrigger" class="bg-main-lighter rounded-full w-23 h-23 flex items-center justify-center focus:outline-none">
		<div v-if="user" :title="user.firstName + ' ' + user.lastName">🍆</div>
	</button>
</template>

<script>
export default {
	name: 'HeaderProfile',
	data () {
		return {
			version: VERSION
		};
	},
	computed: {
		user () {
			return this.$store.state.users.me;
		}
	},
	created () {
		this.$store.dispatch('users/me');
	},
	methods: {
		createContextMenu () {
			const ref = this;
			this.$store.dispatch('contextMenu', {
				source: this.$refs.contextMenuTrigger,
				actions: [
					{
						label: `${this.user.firstName} ${this.user.lastName}`
					},
					{
						label: `${this.user.email}`
					},
					{
						label: `Sniffer v. ${this.version} - AMQP: Active`
					},
					{
						label: 'Logout',
						shortcut: 'l',
						selected: false,
						callback: () => {
							ref.$router.push('/logout');
						}
					}
				]
			});
		}
	}
}
</script>
