import axios from 'axios';

const requestConfig = {
	withCredentials: true,
	headers: {
		'Accept': 'application/json'
	}
};

export default {
	namespaced: true,
	state: {
		all: [],
		me: null
	},
	mutations: {
		all (state, payload) {
			state.all = payload;
		},
		me (state, payload) {
			state.me = payload;
		}
	},
	actions: {
		async all ({ state, commit, rootState }) {
			const subs = await axios.get(process.env.VUE_APP_API_ORIGIN + '/subscriptions', requestConfig);
			commit('all', subs.data);
		},
		async me ({state, commit, rootState }, uid) {
			const sub = await axios.get(process.env.VUE_APP_API_ORIGIN + '/subscriptions/' + uid, requestConfig);
			commit('me', sub.data);
		},
		async new ({state, commit, rootState}, payload) {
			const sub = await axios.post(process.env.VUE_APP_API_ORIGIN + '/subscriptions', payload, requestConfig);
			commit('me', sub.data);
		},
		async update ({state, commit, rootState}, payload) {
			await axios.put(`${process.env.VUE_APP_API_ORIGIN}/subscriptions/${payload.uid}`, payload, requestConfig);
		}
	}
}
