<template>
	<section class="flex items-center justify-center w-full" ref="section">
		<a :href="API + '/sso/login/google'" class="flex items-center border-solid border-main-lighter border-slimmer px-15 py-10 rounded-soft hover:bg-accent">
			<span>
				<svg class="w-15" viewBox="0 0 534 545" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M533.5 278.4C533.5 259.9 532 241.3 528.8 223.1H272.1V327.9H419.1C413 361.7 393.4 391.6 364.7 410.6V478.6H452.4C503.9 431.2 533.5 361.2 533.5 278.4Z" fill="#4285F4"/>
					<path d="M272.1 544.3C345.5 544.3 407.4 520.2 452.5 478.6L364.8 410.6C340.4 427.2 308.9 436.6 272.2 436.6C201.2 436.6 141 388.7 119.4 324.3H28.8999V394.4C75.0999 486.3 169.2 544.3 272.1 544.3V544.3Z" fill="#34A853"/>
					<path d="M119.3 324.3C107.9 290.5 107.9 253.9 119.3 220.1V150H28.9002C-9.6998 226.9 -9.6998 317.5 28.9002 394.4L119.3 324.3V324.3Z" fill="#FBBC04"/>
					<path d="M272.1 107.7C310.9 107.1 348.4 121.7 376.5 148.5L454.2 70.8C405 24.6 339.7 -0.799971 272.1 2.93236e-05C169.2 2.93236e-05 75.0999 58 28.8999 150L119.3 220.1C140.8 155.6 201.1 107.7 272.1 107.7V107.7Z" fill="#EA4335"/>
				</svg>
			</span>
			<span class="ml-15">Login with Google</span>
		</a>
	</section>
</template>

<script>
export default {
	name: 'Login',
	data () {
		return {
			API: process.env.VUE_APP_API_ORIGIN
		};
	},
	methods: {
		updateNav() {
			this.$refs.section.style.minHeight = (window.innerHeight - this.$store.state.refs.header.offsetHeight) + 'px';
		}
	},
	mounted () {
		this.updateNav();
		window.addEventListener('resize', this.updateNav);
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.updateNav);
	},
	watch:{
		$route (to, from) {
			this.updateNav();
		}
	}
}
</script>
