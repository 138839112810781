<template>
	<div class="border-dashed border-slimmer border-main-lighter rounded-sharper mb-20 p-10 box-border text-center">
		<animation animation="cool-man" class="w-30 mx-auto mb-10" />
		<div class="text-40">You don't have any subscriptions yet. Go sniff something!</div>
	</div>
</template>

<script>
import Animation from '@/components/Animation';
export default {
	name: 'EmptySubscription',
	components: {
		Animation
	}
}
</script>
