<template>
	<header class="flex items-center justify-between px-10 sm:px-20 py-10 box-border" ref="header">
		<router-link to="/" aria-label="Home">
			<h1 class="w-20">
				<svg viewBox="0 0 34 35" fill="none">
					<path d="M3.50958 11.3191C0.926528 9.05045 1.20749 3.97979 4.79072 2.58667C7.87624 1.38704 10.5777 2.8636 11.6697 4.56389C12.7617 6.26419 13.3578 10.177 10.4447 11.5756C7.53157 12.9741 6.82603 14.2319 3.50958 11.3191Z" fill="#FEB800"/>
					<path d="M5.96949 5.49722C4.71994 6.43703 5.26723 8.41994 6.20328 9.41547C8.39377 11.7451 10.954 8.29855 8.78451 6.05226C8.00886 5.24917 6.65617 4.98075 5.96949 5.49722Z" fill="#FF1200"/>
					<path d="M30.0705 11.9428C32.8034 9.4751 32.5911 4.05622 28.8727 2.62346C25.6708 1.38971 22.8271 3.01217 21.6599 4.84819C20.4927 6.68421 19.8079 10.8788 22.8266 12.328C25.8454 13.7773 26.5618 15.1112 30.0705 11.9428Z" fill="#FEB800"/>
					<path d="M27.803 5.8482C29.1464 6.85865 28.558 8.99059 27.5516 10.0609C25.1965 12.5656 22.4438 8.86008 24.7764 6.44496C25.6103 5.58151 27.0647 5.29292 27.803 5.8482Z" fill="#FF1200"/>
					<path d="M2.84845 20.9321C1.21946 12.2132 8.44407 4.40651 16.5773 5.43821C25.3115 5.63323 30.9496 10.372 31.8071 17.2375C33.0197 26.9467 28.5985 31.1699 20.2765 32.4081C14.69 33.2393 5.10594 33.0149 2.84845 20.9321Z" fill="#FEB800"/>
					<path d="M20.4916 23.3058C19.862 24.1049 18.7198 24.5609 17.6902 24.2441C17.2029 24.0942 16.2619 23.8941 16.0281 23.4265" stroke="#FF1200" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M16.9582 14.5055C17.4674 15.3354 18.0346 16.1249 18.5866 16.9222C18.7193 17.114 19.1377 17.5362 18.9614 17.7881C18.5873 18.3224 17.434 18.5764 16.7256 18.5764" stroke="#6F01FC" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M23.193 17.3068C23.4684 18.3445 24.088 18.8634 24.5699 19.1748C25.6371 19.6936 26.4289 19.5899 27.4616 18.8634C28.9419 17.8257 28.8042 14.8161 27.8747 12.9481C27.496 12.2217 26.6698 11.7028 26.2223 11.599C24.6044 11.2877 22.0225 12.4293 23.193 17.3068Z" fill="white"/>
					<path d="M6.16245 17.3068C6.43784 18.3445 7.05748 18.8634 7.53943 19.1748C8.60659 19.6936 9.39835 19.5899 10.4311 18.8634C11.9113 17.8257 11.7736 14.8161 10.8442 12.9481C10.4655 12.2217 9.63932 11.7028 9.1918 11.599C7.57385 11.2877 4.99201 12.4293 6.16245 17.3068Z" fill="white"/>
					<path d="M8.15561 15.4091C9.40027 16.2766 8.82412 14.4379 8.28814 15.1079C7.72873 15.8071 8.91462 16.0924 8.91462 15.6259" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M26.3214 15.4091C27.566 16.2766 26.9899 14.4379 26.4539 15.1079C25.8945 15.8071 27.0804 16.0924 27.0804 15.6259" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</h1>
		</router-link>
		<search class="hidden sm:block w-1/3" />
		<header-profile />
	</header>
</template>

<script>
import HeaderProfile from '@/components/HeaderProfile';
import Search from '@/components/Search';

export default {
	name: 'AppHeader',
	components: {
		HeaderProfile,
		Search
	},
	mounted () {
		this.$store.commit('refs/header', this.$refs.header);
	}
}
</script>
