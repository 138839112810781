import axios from 'axios';
import router from '@/router';

const requestConfig = {
	withCredentials: true,
	headers: {
		'Accept': 'application/json'
	}
};

export default {
	namespaced: true,
	state: {
		all: [],
		me: {}
	},
	mutations: {
		all (state, payload) {
			state.all = payload;
		},
		me (state, payload) {
			state.me = payload;
		}
	},
	actions: {
		all ({ state, commit, rootState}) {
			axios.get(process.env.VUE_APP_API_ORIGIN + '/boards', requestConfig)
			.then(response => commit('all', response.data))
			.catch(error => commit('all', []));
		},
		new ({state, commit, rootState}, payload) {
			axios.post(process.env.VUE_APP_API_ORIGIN + '/boards', payload, requestConfig)
			.then(response => commit('all', state.all.concat([response.data])));
		},
		me ({state, commit, rootState}, id) {
			axios.get(process.env.VUE_APP_API_ORIGIN + '/boards/' + id, requestConfig)
			.then(response => commit('me', response.data))
			.catch(error => {
				commit('me', null);
				if (error.request.status === 404) {
					router.replace('/404');
				}
			});
		},
		async update ({state, commit, rootState}, payload) {
			await axios.put(`${process.env.VUE_APP_API_ORIGIN}/boards/${payload.uid}`, payload, requestConfig);
		},
		async addSubscriptions ({state, commit, rootState}, payload) {
			await axios.post(`${process.env.VUE_APP_API_ORIGIN}/boards/${payload.uid}/add-subscriptions`, payload, requestConfig);
		},
		async removeSubscriptions ({state, commit, rootState}, payload) {
			await axios.post(`${process.env.VUE_APP_API_ORIGIN}/boards/${payload.uid}/remove-subscriptions`, payload, requestConfig);
		}
	}
};
