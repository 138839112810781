export default {
	install: (Vue, options) => {

		// add the containers

		// `<!-- Google Tag Manager -->
		// <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		// })(window,document,'script','dataLayer','GTM-NZ58FMG');</script>
		// <!-- End Google Tag Manager -->`

		// `<!-- Google Tag Manager (noscript) -->
		// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NZ58FMG"
		// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
		// <!-- End Google Tag Manager (noscript) -->`

		Vue.prototype.$gtm = {
			sendPageView: () => {
				return window.dataLayer.push({
					event: 'pageView',
					page: {
						requestURI: window.location.pathname + window.location.search,
						page: window.location.pathname + window.location.search,
						location: window.location.href,
						language: 'en', // todo not hardcode this
						referer: window.document.referrer,
						title: window.document.title
					}
				});
			},
			sendEvent: (options) => {
				return window.dataLayer.push({
					event: 'event',
					eventCategory: options.category,
					eventAction: options.action,
					eventLabel: options.label,
					eventValue: options.value,
					eventOptions: options.options
				});
			}
		};
	}
};
