<template>
	<section class="flex items-center justify-center w-full" ref="section">
		<h1>404</h1>
	</section>
</template>

<script>
export default {
	name: 'ErrorView',
	methods: {
		updateNav() {
			this.$refs.section.style.minHeight = (window.innerHeight - this.$store.state.refs.header.offsetHeight) + 'px';
		}
	},
	mounted () {
		this.updateNav();
		window.addEventListener('resize', this.updateNav);
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.updateNav);
	},
	watch:{
		$route (to, from) {
			this.updateNav();
		}
	}
}
</script>
