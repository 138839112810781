<template>
	<article v-if="subscription.active && isVisible" class="border-solid border-main-lighter border-slimmer rounded-soft select-none overflow-hidden" ref="contextMenuRef">

		<div class="flex items-center px-10 py-5 justify-between">
			<span class="text-40 font-semibold opacity-60">{{ subscription.product.status }}</span>
			<button aria-label="open contextual menu" v-if="!!user && (user.uid === subscription.userId || !!user.isAdmin)" v-on:click="createContextMenu" class="text-main hover:text-accent w-15 focus:outline-none">
				<svg viewBox="0 0 20 6" fill="none">
					<circle cx="2.07689" cy="3.04394" r="2.04616" class="fill-current"/>
					<circle cx="9.53075" cy="3.04394" r="2.04616" class="fill-current"/>
					<circle cx="16.9846" cy="3.04394" r="2.04616" class="fill-current"/>
				</svg>
			</button>
		</div>

		<div v-if="subscription.product.status === 'ready'" class="relative pb-full bg-main-lighter">
			<div @mousedown="createDrag" class="absolute inset-0">
				<img :alt="subscription.product.name || subscription.product.title" class="block h-full w-full object-cover" v-if="subscription.product.images && subscription.product.images && subscription.product.images.length" :src="'https://sniffer.ficelle.app/v1/?src=' + encodeURIComponent(subscription.product.images[0]) + '&width=400'" />
				<div v-else class="flex items-center justify-center absolute inset-0">
					<svg class="w-30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M18.3789 10.9786C20.4278 16.5321 22.0466 20.0541 23.1425 25.0587C23.2109 25.285 23.2412 25.5477 23.1981 25.771C23.1119 26.2177 22.696 26.4686 22.2811 26.6821C16.9594 29.5347 10.154 29.3927 4.95086 26.3374C4.47438 26.063 3.99889 25.7511 3.86396 25.2238C3.76128 24.8844 3.80832 24.5115 3.85438 24.176C4.43338 20.5624 5.4204 16.9969 6.73813 13.5897L7.80473 8.41765C7.80473 8.41765 16.1198 4.93314 18.3789 10.9786Z" stroke="#FF1200"/>
						<path d="M1.43266 6.02243C2.24071 5.40624 3.44152 5.07256 4.58193 4.79734C7.02432 4.31706 13.1487 3.47326 18.1072 3.94034C19.765 4.0755 21.5349 4.4059 22.9171 5.22178C24.2994 6.03767 25.1794 7.42225 24.6785 8.51418C24.3488 9.21967 23.5118 9.72585 22.6458 10.122C17.8767 12.3347 11.3034 12.0141 5.33039 10.8642C3.42332 10.4733 1.21914 9.81777 0.578264 8.47784C0.148915 7.59578 0.624608 6.63862 1.43266 6.02243Z" fill="#6F01FC"/>
						<path d="M16.552 3.89967C17.7044 5.14987 18.4945 6.81298 18.1617 8.37739C16.714 9.47174 14.5791 9.50939 13.6926 9.39143C12.1887 9.36942 10.6593 9.04694 9.16925 8.73182C8.69661 8.64358 8.15854 8.4692 7.9889 8.06787C7.91076 7.83148 7.95744 7.58143 7.99745 7.3671C8.20418 6.25973 8.45029 5.15971 8.95877 4.1826C9.46725 3.20549 10.3629 2.33764 11.5197 2.03606C13.3207 1.559 15.2948 2.55596 16.552 3.89967Z" fill="#FEB800"/>
						<path d="M4.31113 24.9549C7.43854 22.6638 11.1475 22.4612 14.9901 22.5626C17.1531 22.6196 21.6358 22.8145 22.9849 24.8743" stroke="#FF1200" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
				<div class="absolute inset-0"></div>
			</div>
		</div>
		<div v-else class="relative pb-full bg-main-lighter">
			<div class="absolute inset-0">
				<div class="flex items-center justify-center absolute inset-0">
					<animation :animation="subscription.product.status === 'uncrawlable' ? 'croix' : newton" class="w-30" />
				</div>
				<div class="absolute inset-0"></div>
			</div>
		</div>

		<div v-if="subscription.product.status === 'ready'" class="px-10 py-5 flex items-center justify-between">
			<div class="w-2/3">
				<a :href="API + '/source/' + subscription.product.uid">
					<h1 class="block whitespace-nowrap overflow-ellipsis overflow-hidden w-full">{{ subscription.product.name || subscription.product.title }}</h1>
				</a>
				<div class="opacity-60">{{ host }}</div>
			</div>
			<div v-if="subscription.currentPrice">
				<div v-if="subscription.fromPrice.price > subscription.currentPrice.price" class="line-through opacity-60">{{ subscription.fromPrice.formattedPrice }}</div>
				<div class="font-semibold">{{ subscription.currentPrice.formattedPrice }}</div>
			</div>
		</div>
		<div v-else class="px-10 py-5 flex items-center justify-start">
			<div class="w-full overflow-hidden">
				<a :href="subscription.url">
					<h1 class="block whitespace-nowrap overflow-ellipsis overflow-hidden w-full">{{ subscription.url }}</h1>
				</a>
				<div class="opacity-60">{{ host }}</div>
			</div>
		</div>
	</article>
</template>

<script>
import Animation from '@/components/Animation';

export default {
	name: 'CardSubscription',
	data () {
		return {
			API: process.env.VUE_APP_API_ORIGIN
		};
	},
	computed: {
		user () {
			return this.$store.state.users.me;
		},
		host () {
			return new window.URL(this.subscription.url).host.replace(/^www\./,'')
		},
		isVisible () {
			const search = this.$store.state.search;

			if (!search) {
				return true;
			}

			if (this.subscription.product.status === 'ready') {
				if (!!this.subscription.product.name.toLowerCase().includes(search)) {
					return true;
				}

				if (!!this.host.toLowerCase().includes(search)) {
					return true;
				}
			}

			return false;
		}
	},
	props: {
		subscription: {
			type: Object,
			required: true
		}
	},
	methods: {
		createDrag () {
			if (!this.user && this.user.uid !== this.subscription.userId) {
				return;
			}

			if (this.subscription.product.status !== 'ready') {
				return;
			}

			this.$store.dispatch('drag', {
				text: this.subscription.product.name || this.subscription.product.title,
				image: this.subscription.product.images ? this.subscription.product.images[0] : null,
				type: 'subscription',
				payload: {
					subscriptions: [this.subscription.uid]
				}
			});
		},
		createContextMenu () {
			const t = this;

			const actions = [
				{
					label: 'Report Error',
					shortcut: 'r',
					selected: !!this.subscription.reported,
					callback: (event) => {
						const value = !t.subscription.reported;

						t.$store.dispatch('subscriptions/update', {
							uid: t.subscription.uid,
							reported: value
						});
						t.$store.dispatch('undo', {
							label: `Reported subscription`,
							undo: () => {
								t.$store.dispatch('subscriptions/update', {
									uid: t.subscription.uid,
									reported: !value
								});
								t.$store.dispatch('boards/all');
							}
						});
					}
				},
				{
					label: 'Snooze',
					shortcut: 's',
					selected: !!this.subscription.notified,
					callback: () => {
						t.$store.dispatch('subscriptions/update', {
							uid: t.subscription.uid,
							notified: !t.subscription.notified
						});
					}
				},
				{
					label: 'Delete',
					shortcut: 'd',
					selected: !this.subscription.active,
					callback: () => {
						t.$store.dispatch('subscriptions/update', {
							uid: t.subscription.uid,
							active: false
						});
						t.$store.dispatch('boards/all');
						t.$store.dispatch('undo', {
							label: `Deleted subscription`,
							undo: () => {
								t.$store.dispatch('subscriptions/update', {
									uid: t.subscription.uid,
									active: true
								});
								t.$store.dispatch('boards/all');
							}
						});
					}
				},
				{
					label: 'Copy UID',
					shortcut: 'c',
					selected: false,
					callback: () => {
						const el = document.createElement('textarea');
						el.value = `Subscription: ${this.subscription.uid}`;
						el.setAttribute('readonly', '');
						el.style.position = 'absolute';
						el.style.left = '-9999px';
						document.body.appendChild(el);

						el.select();
						el.setSelectionRange(0, 99999);
						document.execCommand('copy');
					}
				}
			];

			if (this.$parent.$options.name === 'Board') {
				actions.splice(0, 0,{
					label: 'Remove from bucket',
					shortcut: 'b',
					selected: false,
					callback: () => {
						t.$store.dispatch('boards/removeSubscriptions', {
							uid: t.$parent.board.uid,
							subscriptions: [
								t.subscription.uid
							]
						});
					}
				});
			}

			if (!!this.user && !!this.user.isAdmin) {
				actions.push({
					label: 'Recrawl product',
					shortcut: 'p',
					selected: false,
					callback: () => {
						t.$store.dispatch('products/recrawl', {
							uid: t.subscription.product.uid
						});
					}
				});
			}

			this.$store.dispatch('contextMenu', {
				source: this.$refs.contextMenuRef,
				actions: actions
			});
		}
	},
	components: {
		Animation
	}
}
</script>
