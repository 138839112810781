<template>
	<div ref="ctn"></div>
</template>

<script>
	export default {
		name: 'Animation',
		data () {
			return {
				renderer: null
			};
		},
		props: {
			animation: {
				type: String,
				required: true
			}
		},
		async mounted () {
			const {default: lottie} = await import(
				/* webpackChunkName: "lottie-web" */
				/* webpackMode: "lazy" */
				'lottie-web'
			);

			this.renderer = lottie.loadAnimation({
				container: this.$refs.ctn,
				path: `/animations/${this.animation}.json`,
				renderer: 'svg',
				autoplay: true,
				loop: true,
				name: this.animation
			});
		},
		beforeDestroy () {
			if (!!this.renderer && !!this.renderer.destroy) {
				this.renderer.destroy();
			}
		}
	}
</script>
