import axios from 'axios';
import router from '@/router';

const requestConfig = {
	withCredentials: true,
	headers: {
		'Accept': 'application/json'
	}
};

export default {
	namespaced: true,
	state: {
		loaded: false,
		me: null,
		errors: null
	},
	mutations: {
		me (state, payload) {
			state.me = payload;
			state.loaded = true;
		},
		errors (state, payload) {
			state.errors = !!payload && payload.response ? payload.response.data : payload;
			state.loaded = true;
		}
	},
	actions: {
		me ({ state, commit, rootState }) {
			axios.get(process.env.VUE_APP_API_ORIGIN + '/users/me', requestConfig)
			.then(response => {
				commit('me', response.data);
				if (router.currentRoute.name === 'login') {
					router.push('/');
				}
			}).catch(error => {
				commit('me', null);

				if (!!router.currentRoute.meta.requiresAuth && router.currentRoute.name !== 'login') {
					router.push('/login');
				}
			});
		},
		logout ({ state, commit, rootState}) {
			axios.get(process.env.VUE_APP_API_ORIGIN + '/users/logout', requestConfig)
			.then(response => {
				commit('me', null);
				commit('errors', null);
				router.push('/login');
			});
		},
		async login ({ state, commit, rootState}, payload) {
			await axios.get(process.env.VUE_APP_API_ORIGIN + '/sso/login/google', requestConfig);
		}
	}
}
