import Logout from '@/views/Logout';
import Login from '@/views/Login';

export default [
	{
		name: 'login',
		path: '/login',
		component: Login,
		meta: {
			requiresAuth: false,
			showSidebar: false
		}
	},
	{
		name: 'logout',
		path: '/logout',
		component: Logout,
		meta: {
			requiresAuth: true,
			showSidebar: false
		}
	}
];
