import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

// Routes
import usersRoute from '@/router/users';

// Components
import BoardList from '@/components/BoardList';

// Views
import Home from '@/views/Home';
import Board from '@/views/Board';
import New from '@/views/New';
import ErrorView from '@/views/Error';
import Tos from '@/views/Tos';
import Privacy from '@/views/Privacy';

Vue.use(Router);

const baseRoutes = [
	{
		path: '/',
		name: 'home',
		components: {
			default: Home,
			sidebar: BoardList
		},
		meta: {
			requiresAuth: true,
			resetScroll: true,
			showSidebar: true
		}
	},
	{
		path: '/buckets/:uid',
		components: {
			default: Board,
			sidebar: BoardList
		},
		meta: {
			requiresAuth: true,
			resetScroll: true,
			showSidebar: true
		}
	},
	{
		path: '/new',
		component: New,
		meta: {
			requiresAuth: true,
			resetScroll: true,
			showSidebar: false
		}
	},
	{
		path: '/terms-of-service',
		component: Tos,
		meta: {
			requiresAuth: false,
			resetScroll: true,
			showSidebar: false
		}
	},
	{
		path: '/privacy-policy',
		component: Privacy,
		meta: {
			requiresAuth: false,
			resetScroll: true,
			showSidebar: false
		}
	},
	{
		path: '*',
		component: ErrorView,
		meta: {
			requiresAuth: false,
			resetScroll: true,
			showSidebar: false
		}
	}
];

const routes = baseRoutes.concat(usersRoute);

const router = new Router({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (!!savedPosition && !to.meta.resetScroll) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
})

router.beforeEach((to, from, next) => {
	if (from.name === 'login') {
		next();
		return;
	}
	
	if (!!to.meta.requiresAuth && !!store.state.users.loaded && !store.state.users.me) {
		next('/login');
	} else if (!!store.state.users.me && to.name === 'login' && from.name !== 'logout') {
		next('/');
	} else if (!!to.meta.requires288 && !!store.state.users.me && !store.state.users.me.fromDeuxHuitHuit) {
		next('/');
	} else {
		next();
	}
});

export default router;
