import Vue from 'vue';
import Vuex from 'vuex';

import users from '@/store/users';
import boards from '@/store/boards';
import subscriptions from '@/store/subscriptions';
import refs from '@/store/refs';
import products from '@/store/products';

import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		viewMode: 'grid',
		drag: null,
		contextMenu: null,
		dragAction: null,
		isDragging: false,
		undo: null,
		search: null
	},
	mutations: {
		viewMode (state, payload) {
			state.viewMode = payload;
		},
		drag (state, payload) {
			state.drag = payload;
			state.isDragging = !!state.drag;
		},
		dragAction (state, payload) {
			state.dragAction = payload;
		},
		contextMenu (state, payload) {
			state.contextMenu = payload;
		},
		undo (state, payload) {
			state.undo = payload;
		},
		search (state, payload) {
			state.search = payload.toLowerCase();
		}
	},
	actions: {
		drag: ({ state, commit, rootState }, payload) => commit('drag', payload),
		contextMenu: ({ state, commit, rootState }, payload) => commit('contextMenu', payload),
		undo: ({ state, commit, rootState }, payload) => commit('undo', payload),
		socket ({state, commit, rootState}, payload) {
			const detail = !!router.currentRoute.params.uid && payload.uid === router.currentRoute.params.uid;
			const endpoint = payload.type + (!!detail && !!payload.uid ? '/me' : '/all');
			let p = null;

			if (!!detail) {
				p = payload.uid;
			}

			this.dispatch(endpoint, p);

			// force update for all boards too since they are allways visible in the app
			if (payload.type === 'boards' && endpoint !== 'boards/all') {
				this.dispatch('boards/all');
			}
		}
	},
	modules: {
		users,
		boards,
		subscriptions,
		refs,
		products
	}
});
