<template>
	<div class="border-dashed border-slimmer border-main-lighter rounded-sharper my-10 p-10 box-border text-center">
		<animation animation="bas" class="w-30 mx-auto mb-10" />
		<div class="text-40">Looks like you don't have any buckets set. Create one down below!</div>
	</div>
</template>

<script>
import Animation from '@/components/Animation';

export default {
	name: 'EmptyBoardList',
	components: {
		Animation
	}
}
</script>
