import axios from 'axios';
import Nes from '@hapi/nes/lib/client';

const host = new URL(process.env.VUE_APP_API_ORIGIN).host;
const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';
const client = new Nes.Client(`${protocol}://${host}`);

export default {
	init: async (store) => {
		try {
			client.onUpdate = (message) => store.dispatch('socket', message);

			await axios.get(process.env.VUE_APP_API_ORIGIN + '/nes/auth', {
				withCredentials: true
			});

			await client.connect();

			return client;
		} catch (error) {
			return {};
		}
	}
}
