<template>
	<div class="fixed bottom-20 left-1/2 transform -translate-x-1/2 sm:translate-x-0 sm:left-20 bg-main text-white py-5 px-10 rounded-soft flex items-center whitespace-nowrap">
		<span>{{ undo.label }}</span>
		<button :aria-label="`Undo ${undo.label}`" v-on:click="executeUndo" class="ml-10 text-main bg-accent px-5 py-2 rounded-sharp">undo</button>
	</div>
</template>

<script>
export default {
	name: 'undo',
	computed: {
		undo() {
			return this.$store.state.undo;
		}
	},
	methods: {
		executeUndo() {
			this.undo.undo();
			this.$store.dispatch('undo', null);
		}
	},
	created() {
		const t = this;
		setTimeout(() => {
			t.$store.dispatch('undo', null);
		},5000);
	}
}
</script>
