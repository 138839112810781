<template>
	<div class="w-full flex items-center justify-end">
		<div class="ml-5">
			<button aria-label="Toggle your bucket pubic visibility" v-on:click="togglePublic" :class="{'border-accent hover:border-main-lighter border-slim p-3': !!board.public, 'p-5': !board.public}" class="bg-main-lighter px-10 rounded-sharper border-solid border-main-lighter hover:bg-accent hover:border-accent focus:outline-none">Public</button>
		</div>
		<div class="ml-5">
			<button aria-label="Delete your bucket" v-on:click="deleteBoard" class="bg-main-lighter px-10 p-5 rounded-sharper hover:bg-accent focus:outline-none">Delete</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BoardToolbar',
	props: {
		board: {
			type: Object,
			required: true
		}
	},
	methods: {
		deleteBoard () {
			const t = this;

			this.$store.dispatch('boards/update', {
				uid: this.board.uid,
				active: false
			});

			this.$store.dispatch('undo', {
				label: `Deleted bucket`,
				undo: () => {
					t.$store.dispatch('boards/update', {
						uid: this.board.uid,
						active: true
					});

					t.$router.replace(`/buckets/${this.board.uid}`);
				}
			});

			this.$router.replace('/');
		},
		togglePublic () {
			this.$store.dispatch('boards/update', {
				uid: this.board.uid,
				public: !this.board.public
			});
		}
	}
}
</script>
