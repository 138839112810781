import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import nes from './nes';
import gtm from './gtm';

import './assets/css/tailwind.css';
import './registerServiceWorker';

Vue.config.productionTip = false;

const init = async () => {
	await nes.init(store);

	Vue.use(gtm);
	new Vue({ router, store, render: h => h(App), comments: true }).$mount('#app');

	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('/service-worker.js')
			.then(function (reg) {
				console.log('Service worker registered.');
			}).catch(function (err) {
				console.log('Service worker not registered. This happened:', err);
			});
	}
}

init();
