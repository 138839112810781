<template>
	<div class="absolute flex items-center pointer-events-none" :style="{top: y + 'px', left: x + 'px'}" :class="{'hidden': !isVisible}">
		<div class="w-20">
			<div class="pb-full relative">
				<div class="absolute inset-0 rounded-full shadow-md overflow-hidden bg-main-lighter flex items-center justify-center">
					<img class="block h-full w-full object-cover" v-if="drag.image" :src="'https://sniffer.ficelle.app/v1/?src=' + encodeURIComponent(drag.image) + '&width=40'" />
					<span class="font-semibold" v-if="!drag.image">1</span>
				</div>
			</div>
		</div>
		<div class="bg-main text-white py-3 px-10 rounded-duller shadow-md max-w-60">
			<span class="block whitespace-nowrap overflow-ellipsis overflow-hidden w-full">{{ !!dragAction ? dragAction.text : drag.text }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Drag',
	data () {
		return {
			x: 0,
			y: 0,
			isVisible: false
		};
	},
	computed: {
		drag () {
			return this.$store.state.drag;
		},
		dragAction () {
			return this.$store.state.dragAction;
		}
	},
	methods: {
		onMove (event) {
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

			this.isVisible = true;
			this.x = Math.max(0, Math.min(event.pageX, window.innerWidth - this.$el.offsetWidth - 17));
			this.y = Math.max(0, Math.min(event.pageY, height - this.$el.offsetHeight));
		},
		remove () {
			this.$store.commit('drag', null);
			this.$store.commit('dragAction', null);
		},
		onUp (event) {
			if (this.dragAction) {
				this.$store.dispatch(this.dragAction.dispatch, this.dragAction.payload);
			}
			this.isVisible = false;
			this.remove();
		}
	},
	mounted () {
		window.addEventListener('mousemove', this.onMove);
		window.addEventListener('mouseup', this.onUp);
	},
	beforeDestroy () {
		window.removeEventListener('mousemove', this.onMove);
		window.removeEventListener('mouseup', this.onUp);
	}
}
</script>
