<template>
	<div class="flex items-center mb-10 border-main-lighter border-dashed border-slimmer rounded-soft px-10">
		<animation animation="copy-writer" class="w-20 flex-none" />
		<div class="w-full ml-5">
			<label for="productadd" class="text-hidden">Add a new product</label>
			<input id="productadd" class="w-full bg-transparent focus:outline-none py-10" type="text" v-model="newSubscriptionURL" @keyup.enter="createSubscription" placeholder="Your product's URL">
		</div>
	</div>
</template>

<script>
import Animation from '@/components/Animation';
export default {
	name: 'AddSubscription',
	components: {
		Animation
	},
	data() {
		return {
			newSubscriptionURL: ''
		};
	},
	methods: {
		createSubscription() {
			if (!!this.newSubscriptionURL) {
				this.$store.dispatch('subscriptions/new', {
					url: this.newSubscriptionURL
				});
				this.newSubscriptionURL = '';
			}
		}
	}
}
</script>

<style scoped>
	.text-hidden {
		position:absolute;
		left:-10000px;
		top:auto;
		width:1px;
		height:1px;
		overflow:hidden;
	}
</style>
