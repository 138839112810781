<template>
	<header>
		<h1 class="flex items-center font-serif text-60 font-bold">
			<span class="title">{{ title }}</span>
			<span class="ml-5 text-50">{{ emoji }}</span>
		</h1>
		<span v-if="subtitle">{{ subtitle }}</span>
	</header>
</template>

<script>
export default {
	name: 'PageHeader',
	props: {
		title: {
			type: String,
			required: true
		},
		emoji: {
			type: String,
			required: true
		},
		subtitle: {
			type: String,
			required: false
		}
	}
}
</script>
