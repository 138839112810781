<template>
	<aside v-if="visible" class="self-start fixed z-10 sm:z-0 inset-0 bg-white flex items-center pt-25 sm:pt-10 sm:inset-auto sm:flex flex-col mx-10 sm:mx-20 sm:sticky sm:top-10 content-between sm:w-60 flex-none" ref="aside">
		<button v-on:click="visible = false" class="sm:hidden" aria-label="Close menu">close</button>
		<router-view name="sidebar" />
		<div class="absolute sm:fixed bottom-10 sm:bottom-20 sm:left-20 text-40 opacity-70 font-semibold">v. {{ version }} &copy; Deux Huit Huit</div>
	</aside>
</template>

<script>
export default {
	name: 'AppNav',
	data () {
		return {
			visible: window.innerWidth > 890,
			version: VERSION
		};
	},
	methods: {
		updateNav () {
			this.visible = (window.innerWidth > 890 && this.$route.meta.showSidebar);

			if (!!this.$refs.aside && !!this.visible) {
				this.$refs.aside.style.minHeight = (window.innerHeight - this.$store.state.refs.header.offsetHeight) + 'px';
			}
		}
	},
	mounted () {
		this.updateNav();
		window.addEventListener('resize', this.updateNav);
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.updateNav);
	},
	watch:{
		$route () {
			this.updateNav();
		}
	}
};
</script>
