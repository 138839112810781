<template>
	<section v-if="board.name" class="w-full mx-10 sm:ml-0 sm:mr-20 mb-20">
		<div class="mt-10 w-full flex items-center justify-between">
			<page-header :title="board.name" :subtitle="user.uid !== board.userId ? 'By ' + board.user.firstName + ' ' + board.user.lastName : ''" :emoji="board.emoji" class="flex-none" />
			<board-toolbar :board="board" v-if="user.uid === board.userId" />
		</div>
		<div v-if="board.subscriptions.length" class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-10 mt-20">
			<card-subscription v-for="(subscription, index) in board.subscriptions" :subscription="subscription" :key="index" :edit="board.edit" />
		</div>
		<empty-subscription v-else />
	</section>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import CardSubscription from '@/components/CardSubscription';
import BoardToolbar from '@/components/BoardToolbar';
import EmptySubscription from '@/components/EmptySubscription.vue';

export default {
	name: 'Board',
	computed: {
		user () {
			return this.$store.state.users.me;
		},
		board () {
			return this.$store.state.boards.me;
		},
		viewMode () {
			return this.$store.state.viewMode;
		}
	},
	components: {
		PageHeader,
		CardSubscription,
		BoardToolbar,
		EmptySubscription
	},
	created () {
		this.fetchData();
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		fetchData () {
			this.$store.dispatch('boards/me', this.$route.params.uid);
		}
	}
}
</script>
