<template>
	<section class="w-full">
		<ul>
			<li>
				<router-link class="w-full flex items-center justify-between mb-5 p-5 rounded-sharper" :class="[currentPage === '/' ? 'bg-accent' : '']" to="/" aria-label="home">
					<div class="flex items-center">
						<div>🏠</div>
						<span class="ml-5">Home</span>
					</div>
				</router-link>
			</li>
			<li v-for="(board, index) in boards" :key="index">
				<router-link class="w-full flex items-center justify-between mb-5 p-5 rounded-sharper" :class="[currentPage.includes('/buckets/' + board.uid) ? 'bg-accent' : '']" :to="'/buckets/' + board.uid" :key="$route.params.id" @mouseenter.native="setDragAction" @mouseleave.native="removeDragAction" :data-index="index" :aria-label="board.name">
					<div class="flex items-center">
						<div>{{ board.emoji }}</div>
						<span class="ml-5">{{ board.name }}</span>
					</div>
					<span v-if="board.subscriptions.length > 0" class="text-40 opacity-70 font-semibold flex items-center">{{ board.subscriptions.length }}</span>
				</router-link>
			</li>
			<li v-if="!boards.length">
				<empty-board-list />
			</li>
		</ul>

		<div class="flex items-center mb-5 p-5 rounded-sharper box-border border-dashed border-slimmer border-main-lighter">
			<button v-on:click="emojiIndex + 1 < emojis.length ? emojiIndex++ : emojiIndex = 0" class="select-none focus:outline-none" aria-label="Select another emoji">{{ emojis[emojiIndex] }}</button>
			<label for="bucketadd" class="text-hidden">Add a new bucket</label>
			<input id="bucketadd" class="ml-5 w-full bg-transparent focus:outline-none" ref="inputNewBoard" type="text" v-model="newBoardName" @keyup.enter="createBoard" placeholder="my new bucket">
			<button v-on:click="createBoard" aria-label="Create a new bucket">+</button>
		</div>
	</section>
</template>

<script>

import EmptyBoardList from '@/components/EmptyBoardList';

let timer = null;

export default {
	name: 'BoardList',
	components: {
		EmptyBoardList
	},
	data () {
		return {
			emojis: ['👽', '🍑', '🍆', '👚', '💻', '🚗', '📚'],
			emojiIndex: 0,
			newBoardName: ''
		};
	},
	computed: {
		currentPage() {
			return this.$route.path;
		},
		boards () {
			return this.$store.state.boards.all;
		},
		drag () {
			return this.$store.state.drag;
		}
	},
	created () {
		this.$store.dispatch('boards/all');
	},
	methods: {
		toggleForm () {
			this.formVisible = !this.formVisible;
			this.$nextTick(() => {
				if (this.$refs.inputNewBoard) {
					this.$refs.inputNewBoard.focus();
				}
			});
		},
		createBoard () {
			if (!!this.newBoardName) {
				this.$store.dispatch('boards/new', {
					name: this.newBoardName,
					emoji: this.emojis[this.emojiIndex]
				});
				this.formVisible = false;
				this.newBoardName = '';
			}
		},
		setDragAction (event) {
			if (this.drag) {
				const board = this.boards[parseInt(event.target.getAttribute('data-index'), 10)];
				this.$store.commit('dragAction', {
					text: 'Add to ' + board.name,
					dispatch: 'boards/addSubscriptions',
					payload: {
						uid: board.uid,
						subscriptions: this.drag.payload.subscriptions
					}
				});
			}
		},
		removeDragAction () {
			if (this.drag) {
				this.$store.commit('dragAction', null);
			}
		}
	}
}
</script>
<style scoped>
	.text-hidden {
		position:absolute;
		left:-10000px;
		top:auto;
		width:1px;
		height:1px;
		overflow:hidden;
	}
</style>
