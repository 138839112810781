<template>
	<section class="logout"/>
</template>

<script>
export default {
	name: 'Logout',
	created () {
		this.$store.dispatch('users/logout');
	}
}
</script>
