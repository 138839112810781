<template>
	<div class="w-full mx-10 mt-20">
		<div v-if="title" class="text-center text-55 mx-30">Add <em class="bg-main-lighter rounded-sharper not-italic font-semibold py-2 px-3">{{ title.trim() }}</em> in your buckets.</div>
		<ul v-if="!!boards.length && !!subscription" class="w-full mt-20">
			<li v-for="(board, index) in boards" :key="index" class="flex items-center justify-between w-full" :class="{'mt-10 border-solid border-main-lighter border-t-slimmer pt-10': index > 0}">
				<div class="text-55">
					<span>{{ board.emoji }}</span>
					<span class="inline-block ml-10">{{ board.name }}</span>
				</div>
				<button aria-label="Toggle this subscription in this bucket" v-on:click="toggleSubscription(board, !board.subscriptions.includes(subscription.uid))" class="text-55 focus:outline-none">
					<div v-if="board.subscriptions.includes(subscription.uid)">
						<svg class="w-20" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.76983 10.7849C8.02673 10.6366 6.04618 14.2442 5.8907 16.5899C5.69634 19.5221 6.32695 23.5992 8.88135 24.1892C9.31171 24.2177 10.5223 24.2138 11.9216 23.97C12.2606 23.9109 12.6164 23.8372 12.9851 23.7607C14.3556 23.4766 15.9054 23.1552 17.4414 23.4129C17.4787 23.8867 17.6356 24.2885 17.8777 24.6149C18.2672 25.1401 18.821 25.3935 19.2327 25.471C19.2412 25.4726 19.2497 25.4741 19.2582 25.4753C20.1281 25.6036 21.5426 25.7498 22.9829 25.7751C24.3975 25.7998 25.9369 25.7112 26.9967 25.3142C28.8401 24.6237 30.0591 22.226 29.165 20.1209C29.1611 20.1116 29.157 20.1025 29.1526 20.0935C29.1743 20.0454 29.1948 19.9964 29.2142 19.9465C29.5358 19.1182 29.5037 18.137 29.1274 17.0096C29.3546 16.6446 29.519 16.2422 29.6248 15.8348C29.9161 14.7125 29.8052 13.416 29.2417 12.4351C29.2296 12.414 29.2164 12.3937 29.202 12.3741C28.255 11.084 25.1613 9.09515 19.8193 9.76378C20.3002 7.26036 21.1868 2.56664 18.6282 1.76145C18.2481 1.58373 17.3278 1.67526 16.8143 2.85412L15.2783 8.43734C15.0134 9.53266 14.6932 10.5557 14.3173 11.3722C14.2382 11.544 14.1585 11.7024 14.0787 11.8475L9.76983 10.7849Z" fill="#FEB800"/>
							<path d="M28.9425 12.5224C29.9108 14.208 29.3887 17.1676 27.339 17.7507C25.2893 18.3337 21.0384 17.992 19.1691 17.7483M19.1691 17.7483C18.7289 18.0349 18.3274 18.4832 18.212 18.9924C17.7654 20.9627 18.8936 21.4673 19.4779 21.5894C21.2366 21.8726 25.2422 22.2215 27.1958 21.351C28.776 20.6469 29.5926 19.2905 28.6561 16.8433M19.1691 17.7483C18.3495 17.6414 17.5525 17.278 17.4964 16.3225C17.482 16.0777 17.5251 15.8886 17.5534 15.4621C17.7029 13.2062 23.871 13.9523 25.6746 14.1318M18.9363 21.53C18.4991 21.7717 18.1366 22.2017 18.026 22.6373C17.5983 24.3231 18.6617 24.965 19.2469 25.0752C21.0071 25.3348 25.0135 25.6717 26.9577 24.9435C28.5303 24.3545 29.5987 22.2602 28.8266 20.4423" stroke="#6F01FC" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M7.74431 23.9073C5.47843 22.4177 5.59985 19.4435 5.63832 16.2804C5.66121 15.4021 6.25516 13.4533 6.85563 12.3263C7.03924 12.0235 7.40647 11.4178 7.80273 11.3842C8.90485 11.2371 9.00648 12.5581 9.2932 13.3566C10.1083 15.7949 9.77739 18.3791 9.44876 20.8755C9.28845 21.9699 9.127 23.1083 8.35664 24.0111C8.26655 24.0967 8.1337 24.1371 7.9581 24.1326L7.74431 23.9073Z" fill="#082050"/>
							<path d="M4.84314 16.8566C5.78254 17.0751 7.19194 16.6649 8.05036 16.5206C8.49617 17.1797 8.49617 18.0437 8.05036 18.7028L5.16403 18.7028C4.16391 18.7454 4.14474 19.2684 4.2245 19.5163C4.61997 20.1816 5.11452 21.0478 3.74029 21.2785C2.36605 21.5093 1.96441 20.6361 1.93536 20.1707C2.11248 19.4092 2.35647 18.325 2.10406 17.991C1.78856 17.5737 1.64344 16.43 1.87117 15.451C2.0989 14.4719 2.95763 14.3277 3.74046 14.5097C4.36672 14.6554 4.37125 15.3445 4.29524 15.6708C4.03825 16.1844 3.90375 16.6381 4.84314 16.8566Z" fill="#E4E4E4"/>
						</svg>
					</div>
					<div v-else>+</div>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'New',
	computed: {
		boards () {
			return this.$store.state.boards.all;
		},
		subscription () {
			return this.$store.state.subscriptions.me;
		},
		title () {
			return this.$route.query.title;
		}
	},
	created () {
		if (!!this.$route.query.url || !!this.$route.query.text) {
			this.$store.dispatch('subscriptions/new', {
				// android bug with param attribution with share api. it's always set on text and url is undef.
				url: !!this.$route.query.url ? this.$route.query.url : this.$route.query.text
			});

			this.$store.dispatch('boards/all');
		}
	},
	methods: {
		toggleSubscription(board, fx) {
			this.$store.dispatch(`boards/${!!fx ? 'add' : 'remove'}Subscriptions`, {
				uid: board.uid,
				subscriptions: [
					this.subscription.uid
				]
			});
		}
	}
}
</script>
