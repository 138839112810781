<template>
	<div v-if="visible" class="fixed inset-0 flex items-center justify-center">
		<div v-on:click="remove" class="absolute inset-0 bg-white opacity-60"></div>
		<div class="relative bg-white rounded-sharp overflow-hidden border-solid border-slimmer border-main-lighter shadow-lg max-w-140 w-full p-20 box-border m-20 max-h-90vh overflow-y-auto">
			<animation animation="celebration" class="w-30 mb-10" />
			<h1 class="font-serif text-60 font-bold">Quoi d'neuf dans la v.{{ version }}</h1>
			<ol class="mt-20 list-decimal ml-15">
				<li>Le fait d'avoir un change log</li>
				<li class="mt-5">Ajouter des produits directement dans le dashboard</li>
				<li class="mt-5">Avoir un score de fou dans lighthouse</li>
				<li class="mt-5"><img src="https://shft.cl/img/m/media3.giphy.com-3184111947116364.gif" alt="yep. des gifs."></li>
				<li class="mt-5">Des fixes de bogues d'interface</li>
				<li class="mt-5">Beaucoup de if dans mon code. Je peu enfin dire que Sniffer a un A.I.</li>
				<li class="mt-5">Des oeufs de pâques</li>
			</ol>
			<p class="mt-20">- Dos Mil</p>
			<button aria-label="Close this modal" v-on:click="remove" class="mt-20 border-solid border-main-lighter border-slimmer px-15 py-10 rounded-soft hover:bg-accent">kk thanks bye. 👋</button>
		</div>
	</div>
</template>

<script>
import Animation from '@/components/Animation';
export default {
	name: 'ChangeLogs',
	data () {
		return {
			version: VERSION,
			visible: window.localStorage.getItem('version') !== VERSION
		};
	},
	methods: {
		remove () {
			window.localStorage.setItem('version', VERSION);
			this.visible = false;
		}
	},
	components: {
		Animation
	}
}
</script>
