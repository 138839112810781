<template>
	<div>
		<label for="search" class="text-hidden">Search</label>
		<input autocomplete="off" id="search" type="text" placeholder="Search" v-model="inputText" class="bg-main-lighter text-main focus:outline-none px-10 py-5 w-full rounded-sharper text-center" />
	</div>
</template>

<script>
export default {
	name: 'Search',
	data () {
		return {
			inputText: ''
		};
	},
	watch: {
		inputText () {
			this.$store.commit('search', this.inputText);
		}
	}
}
</script>
<style scoped>
	.text-hidden {
		position:absolute;
		left:-10000px;
		top:auto;
		width:1px;
		height:1px;
		overflow:hidden;
	}
</style>
