<template>
	<div class="fixed inset-0">
		<div v-on:click="removeMenu" class="absolute inset-0 bg-white opacity-60"></div>
		<nav ref="nav" class="bg-white inline-block absolute left-1/2 top-1/2 rounded-sharp overflow-hidden border-solid border-slimmer border-main-lighter shadow-lg">
			<ul>
				<li v-for="(action, index) in contextMenu.actions" :key="index">
					<button :aria-label="`Execute ${action.label}`" v-on:click="executeAction(action, $event)" :class="{'text-accent hover:text-main': !!action.selected, 'border-t-slimmest border-main-lighter border-solid': index > 0, 'hover:bg-accent': !!action.shortcut || !!action.callback}" class="w-full py-5 px-10 flex items-center justify-between focus:outline-none">
						<span>{{ action.label }}</span>
						<span v-if="action.shortcut" class="inline-block ml-10 opacity-60 font-semibold text-40">{{ action.shortcut }}</span>
					</button>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
export default {
	name: 'ContextMenu',
	computed: {
		contextMenu () {
			return this.$store.state.contextMenu;
		},
	},
	methods: {
		removeMenu () {
			this.$store.dispatch('contextMenu', null);
		},
		executeAction (action, event) {
			if (!!action) {
				action.callback(event);
				this.$store.dispatch('contextMenu', null);
			}
		},
		keyboardShortcut (event) {
			if (event.key === 'Escape') {
				return this.$store.dispatch('contextMenu', null);
			}
			this.executeAction(this.contextMenu.actions.filter((action) => !!action.shortcut && action.shortcut === event.key).shift(), event);
		}
	},
	created () {
		window.addEventListener('keyup', this.keyboardShortcut);
	},
	beforeDestroy () {
		window.removeEventListener('keyup', this.keyboardShortcut);
	},
	mounted () {
		const nav = this.$refs.nav;
		const source = this.contextMenu.source;
		const navBounds = nav.getBoundingClientRect();
		const sourceBounds = source.getBoundingClientRect();

		if (window.innerWidth < 890) {
			nav.style.top = ((window.innerHeight / 2) - (navBounds.height / 2)) + 'px';
			nav.style.left = ((window.innerWidth / 2) - (navBounds.width / 2)) + 'px';
		} else {
			nav.style.top = sourceBounds.top + 'px';
			nav.style.left = Math.round(sourceBounds.width) - Math.round(navBounds.width) + sourceBounds.left + 'px';
		}
	}
}
</script>
