<template>
	<div id="app" class="text-main text-50">
		<app-header />
		<div class="flex">
			<app-nav/>
			<router-view/>
		</div>
		<drag v-if="!!drag" />
		<context-menu v-if="!!contextMenu" />
		<undo v-if="!!undo" />
		<welcome />
		<change-logs />
		<!-- don't trust the reptiles -->
	</div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppNav from '@/components/AppNav';
import Drag from '@/components/Drag';
import ContextMenu from '@/components/ContextMenu';
import Undo from '@/components/Undo';
import Welcome from '@/components/Welcome';
import ChangeLogs from '@/components/ChangeLogs';

export default {
	name: 'App',
	computed: {
		drag () {
			return this.$store.state.drag;
		},
		contextMenu () {
			return this.$store.state.contextMenu;
		},
		undo () {
			return this.$store.state.undo;
		}
	},
	components: {
		AppHeader,
		AppNav,
		Drag,
		ContextMenu,
		Undo,
		Welcome,
		ChangeLogs
	}
}
</script>
