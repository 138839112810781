<template>
	<div v-if="visible" class="fixed inset-0 flex items-center justify-center">
		<div v-on:click="remove" class="absolute inset-0 bg-white opacity-60"></div>
		<div class="relative bg-white rounded-sharp overflow-hidden border-solid border-slimmer border-main-lighter shadow-lg max-w-140 p-20 box-border m-20">
			<animation animation="celebration" class="w-30 mx-auto mb-10" />
			<h1 class="font-serif text-60 font-bold">Yo les punks !</h1>
			<p class="mt-10">Amusez-vous bien avec cette nouvelle mouture de Sniffer. ❤</p>
			<p class="mt-10">Utilisez l'option de report pour les produits qui ne sont pas au bon prix. Ça va m'aider a les trouvés plus rapidement dans la BD.</p>
			<p class="mt-10">Amazon n'est toujours pas compatible. Un jour il va l'être. 🤷‍♂️</p>
			<p class="mt-10">Vous avez uniquement accès a sniffer avec votre adresse deuxhuithuit.co</p>
			<p class="mt-10">Merci de garder ce projet confidentiel. 👽</p>
			<p class="mt-20">- Deux Mille</p>
			<button aria-label="Close this modal" v-on:click="remove" class="mt-20 border-solid border-main-lighter border-slimmer px-15 py-10 rounded-soft hover:bg-accent">kk thanks bye. 👋</button>
		</div>
	</div>
</template>

<script>
import Animation from '@/components/Animation';
export default {
	name: 'Welcome',
	data () {
		return {
			visible: this.$route.query.new === 'true'
		};
	},
	methods: {
		remove () {
			this.visible = false;
		}
	},
	components: {
		Animation
	}
}
</script>
