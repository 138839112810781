<template>
	<section class="w-full mx-10 sm:ml-0 sm:mr-20 mb-20 mt-10">
		<add-subscription />
		<div v-if="!!subscriptions.length" class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-10">
			<card-subscription v-for="(subscription, index) in subscriptions" :subscription="subscription" :key="index" />
		</div>
		<empty-subscription v-else />
	</section>
</template>

<script>
import CardSubscription from '@/components/CardSubscription';
import EmptySubscription from '@/components/EmptySubscription.vue';
import AddSubscription from '@/components/AddSubscription.vue';

export default {
	name: 'Home',
	components: {
		CardSubscription,
		EmptySubscription,
		AddSubscription
	},
	computed: {
		subscriptions () {
			return this.$store.state.subscriptions.all;
		},
		viewMode () {
			return this.$store.state.viewMode;
		}
	},
	created () {
		this.$store.dispatch('subscriptions/all');
	}
}
</script>
