<template>
	<section class="max-w-120 m-20">
		<h1 class="font-serif text-60 font-bold">Terms of Service</h1>
		<small>Effective date: 2021-01-06</small>
		<p class="mt-10">By using the https://app.pricesniffer.co/ web site ("Service"), or any of its related services, including but not limited to any browser plugin (extensions) of Price Sniffer, you are agreeing to be bound by the following terms and conditions ("Terms of Service").</p>
		<p class="mt-10">YOU USE THE SERVICES AT YOUR OWN RISK.</p>
		<p class="mt-10">THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SERVICE.</p>
		<p class="mt-10">You may not attempt to reverse engineer or otherwise derive source code, trade secrets, or know-how in the Service.</p>
		<p class="mt-10">You need to own the rights to copy (distribute) any file you wish to post to the Service.</p>
		<p class="mt-10">You must not use the service to host pornographic, violent, racist, illegal or homophobic content. Such content will be deleted without warning.</p>
		<p class="mt-10">IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY, ITS AFFILIATES AND ALL USERS WHO ACCESS OUR SERVICES THROUGH YOUR ACCOUNT TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS ASSOCIATED WITH IT. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>
		<p class="mt-10">Any legal action shall take place in Montréal, Québec, Canada.</p>
		<p class="mt-10">We reserve the right to change this agreement at any time, without prior notice.</p>
	</section>
</template>

<script>
export default {
	name: 'TOS',
}
</script>
