export default {
	namespaced: true,
	state: {
		header: null
	},
	mutations: {
		header (state, ref) {
			state.header = ref;
		}
	}
};
