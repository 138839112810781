import axios from 'axios';

const requestConfig = {
	withCredentials: true,
	headers: {
		'Accept': 'application/json'
	}
};

export default {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async recrawl ({state, commit, rootState}, payload) {
			await axios.post(`${process.env.VUE_APP_API_ORIGIN}/products/recrawl`, payload, requestConfig);
		},
	}
};
